<template>
  <div>
    <x-table
      tableType="card"
      :options="options"
      @search="searchHandle"
      @rowAdd="addHandle"
      @rowEdit="rowEdit"
      @rowEnable="rowEnable"
      @rowPause="rowPause"
      @rowRecovery="rowRecovery"
      @rowStop="rowStop"
      @rowDelete="rowDelete"
      @beforeCardCreate="beforeCardCreate">
      <template slot="form-state" slot-scope="{data}">
        <div class="d-flex align-items-center">
          <div class="x-dot" :class="options.columns[4].selectVariant(data)"></div>
          {{data.text}}
        </div>
      </template>
      <template slot="form-configuration-routes" slot-scope="{row,data,type}">
        <b-row class="x-w-80 mr-50">
          <b-col md="12" class="d-flex align-items-center justify-content-end">
            <b-form-input :disabled="type === 'view'" v-model="row.configuration.routes[data].url" placeholder="/**" />
            <feather-icon icon="ChevronRightIcon" size="40" class="ml-1 mr-1"/>
            <v-select
              v-model="row.configuration.routes[data].protocol"
              :disabled="type === 'view'"
              :options="columns[3].children[0].dictData"
              :reduce="option => option.value"
              label="name"
              :getOptionLabel="option => option.label"
              class="select-size-sm x-w-100"
              placeholder="请选择协议" >
              <template slot="no-options">{{$t('noData')}}</template>
            </v-select>
          </b-col>
        </b-row>
      </template>
    </x-table>
  </div>
</template>

<script>
import XTable from '@core/components/cx/table/XTable.vue'
import {
  BCol, BRow, BFormInput,
} from 'bootstrap-vue'
import {
  queryPages, startGateway, pauseGateway, stopGateway, deleteGateway, addGateway, editGateway,
} from '@/api/device-access/gateway'
import vSelect from 'vue-select'

export default {
  directives: {
  },
  components: {
    XTable,
    BCol,
    BRow,
    BFormInput,
    vSelect,
  },
  data() {
    return {
      columns: undefined,
      networkComponents: undefined,
      content: [{
        title: '接入网关用于连接设备、处理设备消息！',
      }, {
        title: '第一步：创建接入网关',
        prop: 1,
        content: '打开接入网关菜单,选择新增，填入网关名称、选择网关类型并完善信息，点击保存即完成创建。',
      }, {
        title: '第二步：开启接入网关',
        content: '在更多选项中选择启用即完成接入网关启用，启用成功后可使用设备进行连接测试！',
      }],
      options: {
        defaultIconText: 'GW',
        formType: 'sidebar',
        formWidth: '40%',
        // 导出按钮
        exportBtn: false,
        // 打印按钮
        printBtn: false,
        cardProps: {
          icon: 'photoUrl',
          name: 'name',
        },
        cardColumnCols: 4,
        collapseExpandAll: true,
        actions: [{ action: 'view', name: '查看', icon: 'EyeIcon' }, { action: 'edit', name: '编辑', icon: 'EditIcon' },
          { action: 'enable', name: '启用', icon: 'PlayIcon' }, { action: 'pause', name: '暂停', icon: 'PauseCircleIcon' },
          { action: 'recovery', name: '恢复', icon: 'RepeatIcon' }, { action: 'stop', name: '停止', icon: 'PowerIcon' },
          { action: 'delete', name: '删除', icon: 'Trash2Icon' }],
        actionFilter: (action, data) => {
          if (action === 'view' || action === 'edit') {
            return true
          }
          switch (data.state.value) {
            case 'enabled':
              return action === 'pause' || action === 'stop'
            case 'paused':
              return action === 'recovery'
            case 'disabled':
              return action === 'enable' || action === 'delete'
            default:
              return false
          }
        },
        columns: [{
          label: '名称',
          labelShow: true,
          prop: 'name',
          searchShow: true,
          sortable: true,
          viewShow: false,
          tip: '网关名称',
          rules: {
            rule: 'required',
          },
        }, {
          label: '网关类型',
          labelShow: true,
          prop: 'provider',
          searchShow: false,
          type: 'select',
          sortable: true,
          tip: '网关类型',
          dictUrl: '/gateway/device/providers',
          onDictSelected: this.onDictSelected,
          props: {
            label: 'name',
            value: 'id',
          },
          rules: {
            rule: 'required',
          },
        }, {
          label: '网络组件',
          labelShow: true,
          prop: 'networkId',
          searchShow: false,
          type: 'select',
          sortable: true,
          tip: '网络组件',
          linkUrl: '/device-center/access/network-components',
          dictUrl: '/network/config/_query/no-paging',
          props: {
            label: 'name',
            value: 'id',
          },
          rules: {
            rule: 'required',
          },
        }, {
          label: '配置',
          labelShow: true,
          prop: 'configuration',
          searchShow: false,
          rowShow: false,
          tip: '配置',
          addShow: false,
          editShow: false,
          viewShow: false,
          children: [
            {
              label: '接入协议',
              type: 'select',
              labelShow: true,
              prop: 'protocol',
              dictUrl: '/protocol/supports',
              props: {
                label: 'name',
                value: 'id',
              },
              searchShow: false,
              rowShow: false,
              tip: '接入协议',
            }, {
              label: '协议路由',
              labelShow: true,
              type: 'repeater',
              prop: 'routes',
              searchShow: false,
              rowShow: false,
              tip: '协议路由',
              addShow: false,
              editShow: false,
              viewShow: false,
            }, {
              label: '订阅主题',
              labelShow: true,
              prop: 'topics',
              searchShow: false,
              rowShow: false,
              type: 'textarea',
              tip: '从MQTT Broker订阅Topic，多个使用，分隔',
              placeholder: '从MQTT Broker订阅Topic，多个使用，分隔',
              addShow: false,
              editShow: false,
              viewShow: false,
            }, {
              label: 'Qos',
              labelShow: true,
              prop: 'qos',
              searchShow: false,
              rowShow: false,
              type: 'radio',
              dictData: [{ label: 0, value: 0 }, { label: 1, value: 1 }, { label: 2, value: 2 }],
              addShow: false,
              editShow: false,
              viewShow: false,
            },
          ],
        }, {
          label: '状态',
          labelShow: true,
          prop: 'state',
          searchShow: false,
          type: 'select',
          addShow: false,
          sortable: true,
          editShow: false,
          rowSelectDot: true,
          dictData: [{ label: '已启动', value: 'enabled' }, { label: '已暂停', value: 'paused' }, { label: '已停止', value: 'disabled' }],
          selectVariant: prop => {
            switch (prop.value) {
              case 'paused':
                return 'text-warning'
              case 'disabled':
                return 'text-danger'
              case 'enabled':
              default:
                return 'text-success'
            }
          },
        },
        {
          label: '描述',
          labelShow: true,
          prop: 'describe',
          searchShow: false,
          rowShow: false,
          type: 'textarea',
        },
        ],
      },
    }
  },
  watch: {
  },
  methods: {
    searchHandle(page, params, done) {
      queryPages(page.pageIndex - 1, page.pageSize, params).then(resp => {
        done(resp.data.result.data, resp.data.result.total)
      })
    },
    rowEdit(data, done) {
      editGateway(data).then(() => {
        done()
      }).catch(() => {
        done()
      })
    },
    rowDelete(data, done) {
      deleteGateway(data.id).then(() => {
        done()
      }).catch(() => {
        done()
      })
    },
    rowEnable(data, done) {
      startGateway(data.id).then(() => {
        done()
      }).catch(() => {
        done()
      })
    },
    rowPause(data, done) {
      pauseGateway(data.id).then(() => {
        done()
      }).catch(() => {
        done()
      })
    },
    rowRecovery(data, done) {
      startGateway(data.id).then(() => {
        done()
      }).catch(() => {
        done()
      })
    },
    rowStop(data, done) {
      stopGateway(data.id).then(() => {
        done()
      }).catch(() => {
        done()
      })
    },
    beforeCardCreate(data) {
      data.describe = '111111'
    },
    addHandle(data, done) {
      addGateway(data).then(() => {
        done()
      }).catch(() => {
        done()
      })
    },
    onDictSelected(option, columns, ftype) {
      if (ftype === 'search') {
        return
      }
      if (!this.columns) {
        this.columns = columns
      }
      if (!this.networkComponents) {
        this.networkComponents = [...this.columns[2].dictData]
      }
      if (!option) {
        columns[2].viewShow = false
        columns[2].addShow = false
        columns[2].editShow = false
        columns[3].viewShow = false
        columns[3].addShow = false
        columns[3].editShow = false
        columns[4].viewShow = false
        columns[4].addShow = false
        columns[4].editShow = false
        return
      }
      switch (option) {
        case 'websocket-server':
        case 'http-server-gateway': {
          columns[2].viewShow = true
          columns[2].addShow = true
          columns[2].editShow = true
          columns[3].viewShow = true
          columns[3].addShow = true
          columns[3].editShow = true
          columns[3].children[0].viewShow = false
          columns[3].children[0].addShow = false
          columns[3].children[0].editShow = false
          columns[3].children[1].viewShow = true
          columns[3].children[1].addShow = true
          columns[3].children[1].editShow = true
          columns[3].children[2].viewShow = false
          columns[3].children[2].addShow = false
          columns[3].children[2].editShow = false
          columns[3].children[3].viewShow = false
          columns[3].children[3].addShow = false
          columns[3].children[3].editShow = false
          columns[4].viewShow = false
          columns[4].addShow = false
          columns[4].editShow = false
          break
        }
        default: {
          columns[2].viewShow = true
          columns[2].addShow = true
          columns[2].editShow = true
          columns[3].viewShow = true
          columns[3].addShow = true
          columns[3].editShow = true
          columns[3].children[0].viewShow = true
          columns[3].children[0].addShow = true
          columns[3].children[0].editShow = true
          columns[3].children[1].viewShow = false
          columns[3].children[1].addShow = false
          columns[3].children[1].editShow = false
          if (option === 'mqtt-client-gateway') {
            columns[3].children[2].viewShow = true
            columns[3].children[2].addShow = true
            columns[3].children[2].editShow = true
            columns[3].children[3].viewShow = true
            columns[3].children[3].addShow = true
            columns[3].children[3].editShow = true
          } else {
            columns[3].children[2].viewShow = false
            columns[3].children[2].addShow = false
            columns[3].children[2].editShow = false
            columns[3].children[3].viewShow = false
            columns[3].children[3].addShow = false
            columns[3].children[3].editShow = false
          }
          break
        }
      }
      // 查询网络类型
      let networkType
      for (let type of this.columns[1].dictData) {
        if (type.row.id === option) {
          networkType = type
          break
        }
      }
      // 查询网络组件
      if (networkType) {
        let networks = []
        for (let network of this.networkComponents) {
          if (network.row.type === networkType.row.networkType.value) {
            networks.push(network)
          }
        }
        columns[2].dictData = networks
      }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
