import request from '@/auth/jwt/useJwt'

/*  分页查询组件列表  */
export function queryPages(pageNo, pageSize, params) {
  let url = `gateway/device/_query?pageIndex=${pageNo}&pageSize=${pageSize}&sorts[0].name=id&sorts[0].order=desc`
  if (false && params && params.name) {
    url += `&terms[0].value=%${params.name}%&terms[0].termType=like`
  }
  return request.axiosIns({
    url,
    method: 'get',
  })
}
/*  查询协议  */
export function queryAgreement() {
  return request.axiosIns({
    url: 'protocol/supports',
    method: 'get',
  })
}
/* 获得网关类型 */
export function queryGatewayCategory() {
  return request.axiosIns({
    url: 'gateway/device/providers',
    method: 'get',
  })
}
/* 根据类型获取网络组件 */
export function networkDetails(id) {
  return request.axiosIns({
    url: `network/config/${id}/_detail`,
    method: 'get',
  })
}

/* 根据id获取网络组件 */
export function getNetworkById(id) {
  return request.axiosIns({
    url: `network/config/${id}`,
    method: 'get',
  })
}
// 新增网络组件
export function add(data) {
  return request.axiosIns({
    url: 'network/config',
    method: 'PATCH',
    data,
  })
}
// 编辑网络组件
export function edit(data) {
  return request.axiosIns({
    url: 'network/config',
    method: 'PATCH',
    data,
  })
}
// 删除网络组件
export function remove(id) {
  return request.axiosIns({
    url: `network/config/${id}`,
    method: 'delete',
  })
}
export function getById(id) {
  return request.axiosIns({
    url: `network/config/${id}`,
    method: 'get',
  })
}
// 启动组件
export function start(id) {
  return request.axiosIns({
    url: `network/config/${id}/_start`,
    method: 'post',
  })
}
// 停止组件组件
export function shutdown(id) {
  return request.axiosIns({
    url: `network/config/${id}/_shutdown`,
    method: 'post',
  })
}
// 启动接入网关
export function startGateway(id) {
  return request.axiosIns({
    url: `/gateway/device/${id}/_startup`,
    method: 'post',
  })
}

// 暂停接入网关
export function pauseGateway(id) {
  return request.axiosIns({
    url: `/gateway/device/${id}/_pause`,
    method: 'post',
  })
}

// 停止接入网关
export function stopGateway(id) {
  return request.axiosIns({
    url: `/gateway/device/${id}/_shutdown`,
    method: 'post',
  })
}

// 删除接入网关
export function deleteGateway(id) {
  return request.axiosIns({
    url: `/gateway/device/${id}`,
    method: 'delete',
  })
}
// 新增接入网关
export function addGateway(data) {
  return request.axiosIns({
    url: '/gateway/device',
    method: 'PATCH',
    data,
  })
}
// 编辑接入网关
export function editGateway(data) {
  return addGateway(data)
}
